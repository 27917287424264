@use './styles/global' as g;
/* Arbitrary Values */
.w-20 {
	width: 20% !important;
}
.w-40 {
	width: 40% !important;
}
.w-60 {
	width: 60% !important;
}
.w-100 {
	width: 100% !important;
}

.w700 {
	font-weight: 700 !important;
}

/* Hide the built-in password visibility icon */
input[type='password']::-ms-reveal {
	display: none;
}

.staticText {
	font-size: g.$label;
}

.text-global {
	font-size: g.$staticText;
	font-weight: g.$normalWeight;
	line-height: g.$staticTextLineheight;
	color: g.$neutral7;
}

.underline {
	text-decoration: underline;
}

.primary {
	color: g.$primary !important;
}
