@use './global' as g;

.cardStructure {
	border-radius: 6px;
	box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.06);
	background-color: white;
}

.verticalContent {
	display: flex;
	flex-direction: column;
}

.verticalContentHorizontallyCenter {
	@extend .verticalContent;
	justify-content: center;
	align-items: flex-start;
}

.verticalContentSpacedBetween {
	@extend .verticalContent;
	justify-content: space-between;
}

.horizontalContent {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.horizontalContentSpacedBetween {
	@extend .horizontalContent;
	justify-content: space-between;
}

.cardContainer {
	@extend .cardStructure;
	padding: 15px 30px 30px;
	// gap: 30px;
	margin-bottom: 30px;
}

.infoLeftAndRight {
	@extend .horizontalContentSpacedBetween;
	align-items: center;
	gap: 24px;
}

.sectionIntro p {
	margin-bottom: 0;
	margin-top: 7px;
}

.listOfCards {
	margin-bottom: 20px;
	align-items: stretch;
	gap: 24px;
	display: flex;

	.singleCard {
		@extend .cardStructure;
		padding: 25px 20px 10px;
		flex-grow: 1;
		min-width: 0;
	}
}
